import React from 'react';
import Spinner from 'react-spinkit';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import './index.scss';

const Loading = () => (
  <div className="loading-wrapper">
    <Spinner name="double-bounce" fadeIn="none" />
    <FormattedMessage id="loading" defaultMessage="Loading...">
      {loading => <p>{loading}</p>}
    </FormattedMessage>
  </div>
);

Loading.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(Loading);
