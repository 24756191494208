import React from 'react';
import { Header, Image, Table, Grid } from 'semantic-ui-react';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';

import { object } from 'prop-types';

import './index.scss';

const EntiServiziStruttureView = ({ item }) => {
  const venue = [
    item.Indirizzo,
    item.Localita,
    item.Comune === '036023' ? 'Modena' : null,
    item.Provincia,
    item.CAP
  ]
    .filter(el => ['', null, undefined].indexOf(el) === -1)
    .join(' - ');

  const phone = item.Telefono ? `+39 ${item.Telefono}` : null;

  const email = item['e-mail'] ? (
    <a href={`mailto:${item['e-mail']}`}>{item['e-mail']}</a>
  ) : null;

  const url = item.url ? <a href={item.url}>{item.url}</a> : null;

  const orario =
    item.Orario && item.Orario.data ? (
      <div dangerouslySetInnerHTML={{ __html: item.Orario.data }} />
    ) : null;

  const annotazioni =
    item.annotazioni && item.annotazioni.data ? (
      <div dangerouslySetInnerHTML={{ __html: item.annotazioni.data }} />
    ) : null;

  const text =
    item.text && item.text.data ? (
      <div dangerouslySetInnerHTML={{ __html: item.text.data }} />
    ) : null;

  const parseImage = index => {
    let newImage = {
      url: '',
      alt: ''
    };

    if (item[`immagine${index}`]) {
      newImage.url = item[`immagine${index}`].scales.large.download;

      if ([null, undefined, ''].indexOf(item[`alt_immagine${index}`]) === -1) {
        newImage.alt = item[`alt_immagine${index}`];
      }

      return newImage;
    }

    return null;
  };

  const firstImage = parseImage(1);
  const images = [2, 3, 4].map(parseImage).filter(img => img !== null);

  return (
    <div className="enti-strutture-view">
      <div id="content">
        {item.leadImage && (
          <figure className="leadImage">
            <Image src={item.leadImage.scales.large.download} />
          </figure>
        )}
        <Header as="h2">{item.title}</Header>
        {item.description &&
          item.description.data && (
            <span className="description">
              <div
                dangerouslySetInnerHTML={{ __html: item.description.data }}
              />
            </span>
          )}
        {text && (
          <div className="item-text">
            {firstImage && (
              <Image
                className="first-image-floating"
                src={firstImage.url}
                alt={firstImage.alt}
              />
            )}
            {text}
          </div>
        )}
        {images && (
          <div className="images-wrapper">
            <Grid doubling stackable columns={3}>
              {images.map((img, idx) => (
                <Grid.Column key={idx}>
                  <figure className={`image-${idx + 1}`}>
                    <Image src={img.url} alt={img.alt} />
                  </figure>
                </Grid.Column>
              ))}
            </Grid>
          </div>
        )}
        <Table celled selectable stackable definition className="info-table">
          <Table.Body>
            {venue.length && (
              <Table.Row>
                <Table.Cell>
                  <FormattedMessage id="venue" defaultMessage="Venue" />
                </Table.Cell>
                <Table.Cell>{venue}</Table.Cell>
              </Table.Row>
            )}
            {phone && (
              <Table.Row>
                <Table.Cell>
                  <FormattedMessage id="phone" defaultMessage="Phone" />
                </Table.Cell>
                <Table.Cell>{phone}</Table.Cell>
              </Table.Row>
            )}
            {email && (
              <Table.Row>
                <Table.Cell>
                  <FormattedMessage id="email" defaultMessage="E-mail" />
                </Table.Cell>
                <Table.Cell>{email}</Table.Cell>
              </Table.Row>
            )}
            {url && (
              <Table.Row>
                <Table.Cell>
                  <FormattedMessage id="website" defaultMessage="Website" />
                </Table.Cell>
                <Table.Cell>{url}</Table.Cell>
              </Table.Row>
            )}
            {orario && (
              <Table.Row>
                <Table.Cell>
                  <FormattedMessage id="timetable" defaultMessage="Timetable" />
                </Table.Cell>
                <Table.Cell>{orario}</Table.Cell>
              </Table.Row>
            )}
            {annotazioni && (
              <Table.Row>
                <Table.Cell>
                  <FormattedMessage id="notes" defaultMessage="Notes" />
                </Table.Cell>
                <Table.Cell>{annotazioni}</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

EntiServiziStruttureView.propTypes = {
  item: object,
  intl: intlShape.isRequired
};

export default injectIntl(EntiServiziStruttureView);
