import React, { Component } from 'react';
import { isEqual } from 'lodash';
import { searchData } from '../../../helpers/api';
import ListingResultsContainer from '../ListingResultsContainer';
import Error from '../../Error';
import './index.scss';
import SiteLink from '../../../helpers/SiteLink';

const headerHeight = 90;

class ListingContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      batching: null,
      items_total: 0,
      loading: true,
      error: null,
      b_size: this.props.query['b_size'] || 25,
      activePage: 1,
      SearchableText: ''
    };
  }

  componentDidMount() {
    this.getData();
    this.timerId = null;
  }
  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.SearchableText !== this.state.SearchableText) {
      clearTimeout(this.timerId);
      const timerId = setTimeout(() => this.getData(), 500);
      this.timerId = timerId;
    } else if (
      !isEqual(this.props.query, prevProps.query) ||
      prevState.activePage !== this.state.activePage
    ) {
      this.setState({ ...this.state, loading: true });
      this.getData();
    }
  }

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ ...this.state, activePage });
    //this.state.node.scrollIntoView();

    window.scroll({
      top: this.state.node.offsetTop - headerHeight,
      behavior: 'smooth'
    });
  };

  handleSearchText = (event) => {
    this.setState({ SearchableText: event.target.value });
  };

  getData = () => {
    const { query } = this.props;
    const { b_size, activePage, SearchableText } = this.state;
    query['b_size'] = b_size;

    let b_start = 0;
    if (activePage !== 1) {
      // add pagination infos
      b_start = b_size * (activePage - 1);
    }

    let search_query = {
      ...query,
      b_start,
      b_size
    };
    if (SearchableText) {
      search_query = { ...search_query, SearchableText };
    }

    searchData(search_query)
      .then((data) => {
        this.setState({
          ...this.state,
          batching: null,
          ...data,
          loading: false
        });
      })
      .catch((error) => {
        console.error('Error: ', error);
        this.setState({
          items: [],
          loading: false,
          error: error,
          SearchableText: ''
        });
      });
  };

  render() {
    const { error } = this.state;
    const { title, location, align, linkMore, linkMoreTitle } = this.props;

    return error ? (
      <Error data={error} />
    ) : (
      <React.Fragment>
        <div
          className="listing-container"
          ref={(node) => {
            this.state.node = node;
          }}
        >
          {/* <Input
            icon="search"
            placeholder="Search..."
            onChange={this.handleSearchText}
            value={SearchableText}
          /> */}
          {title && <h2>{title}</h2>}
          <ListingResultsContainer
            {...this.state}
            location={location}
            handlePaginationChange={this.handlePaginationChange}
            align={align}
          />
          {linkMore && (
            <div className="linkMore">
              <SiteLink to={linkMore}>{linkMoreTitle}</SiteLink>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default ListingContainer;
