import React from 'react';
import { Message } from 'semantic-ui-react';
import { intlShape, injectIntl } from 'react-intl';
import './index.scss';

const Error = ({ intl, data }) => {
  const header = (
    <h2>
      {intl.formatMessage({
        id: 'error',
        defaultMessage: 'Error'
      })}
    </h2>
  );

  const content = (
    <div className="error-content">
      <p>
        {intl.formatMessage({
          id: 'error.message',
          defaultMessage: 'Something went wrong, try later.'
        })}
      </p>
      {data && (
        <p>
          <pre>{data.toString()}</pre>
        </p>
      )}
    </div>
  );

  return (
    <div className="error-wrapper">
      <Message negative icon="warning sign" header={header} content={content} />
    </div>
  );
};

Error.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(Error);
