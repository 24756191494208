import React from 'react';
import { Link } from 'react-router-dom';
import InstallPWA from '../InstallPWA';
import './index.scss';

import { intlShape, injectIntl, FormattedMessage } from 'react-intl';

const Homepage = ({ intl }) => (
  <div id="homepage-view">
    <div className="homepage-links">
      <ul>
        <li id="visita">
          <Link
            to={intl.formatMessage({
              id: 'visitLink',
              defaultMessage: '/visit'
            })}
          >
            <FormattedMessage id="visit" defaultMessage="Explore">
              {text => <h2 className="link-title">{text}</h2>}
            </FormattedMessage>
          </Link>
          <FormattedMessage
            id="visitDescription"
            defaultMessage="Your Journey Begins"
          >
            {text => <p className="link-description">{text}</p>}
          </FormattedMessage>
        </li>
        <li id="partecipa">
          <Link
            to={intl.formatMessage({
              id: 'participateLink',
              defaultMessage: '/participate'
            })}
          >
            <FormattedMessage id="participate" defaultMessage="Have Fun">
              {text => <h2 className="link-title">{text}</h2>}
            </FormattedMessage>
          </Link>
          <FormattedMessage
            id="participateDescription"
            defaultMessage="Experience an Event"
          >
            {text => <p className="link-description">{text}</p>}
          </FormattedMessage>
        </li>
        <li id="prenota">
          <Link
            to={intl.formatMessage({
              id: 'bookLink',
              defaultMessage: '/book'
            })}
          >
            <FormattedMessage id="book" defaultMessage="Make Reservations">
              {text => <h2 className="link-title">{text}</h2>}
            </FormattedMessage>
          </Link>
          <FormattedMessage
            id="bookDescription"
            defaultMessage="Find Your Activity"
          >
            {text => <p className="link-description">{text}</p>}
          </FormattedMessage>
        </li>
      </ul>
    </div>
    <InstallPWA />
  </div>
);

Homepage.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(Homepage);
