import React from 'react';
import { Icon } from 'semantic-ui-react';
import ScrollUpButton from 'react-scroll-up-button';

import './index.scss';

const ScrollToTop = () => (
  <ScrollUpButton
    ContainerClassName="scroll-to-top"
    TransitionClassName="scroll-button-shown"
    ShowAtPosition={160}
    AnimationDuration={300}
  >
    <Icon name="angle up" />
  </ScrollUpButton>
);

export default ScrollToTop;
