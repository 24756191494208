import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Container, Dropdown } from 'semantic-ui-react';
import { AppContext } from '../../App';
import OffcanvasMenu from '../OffcanvasMenu';

import './index.scss';

const AppHeader = () => (
  <header>
    <Container>
      <div id="header">
        <OffcanvasMenu />
        <div className="header-content">
          <Link to="/">
            <Header as="h1">VisitModena NOW</Header>
          </Link>
        </div>
        <AppContext.Consumer>
          {({ language, setLanguage }) => (
            <Dropdown inline text={language.toUpperCase()} direction="left">
              <Dropdown.Menu>
                <Dropdown.Item active={language === 'it'}>
                  <Link to="/" onClick={setLanguage('it')}>
                    IT
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item active={language === 'en'}>
                  <Link to="/" onClick={setLanguage('en')}>
                    EN
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </AppContext.Consumer>
      </div>
    </Container>
  </header>
);

export default AppHeader;
