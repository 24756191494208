import React from 'react';

import { intlShape, injectIntl } from 'react-intl';
import TagsView from '../TagsView';
import EventWhen from './EventWhen';
import DefaultImageSVG from '../default-image.svg';
import SiteLink from '../../../../helpers/SiteLink';

const EventItem = ({ intl, item }) => {
  const image = item.image ? item.image.scales.large.download : DefaultImageSVG;

  return (
    <div className="item event-item">
      <div className="item-content">
        <SiteLink to={item['@id']}>
          {item.thematic_area && (
            <TagsView subjects={[item.thematic_area.title]} />
          )}
          <div className="item-info">
            <EventWhen
              start={item.start}
              end={item.end}
              whole_day={item.whole_day}
              open_end={item.open_end}
              show_times={false}
            />
          </div>
          <figure>
            <img src={image} alt={item.title} loading="lazy" />
          </figure>
        </SiteLink>
      </div>
      <div className="item-title">
        <SiteLink to={item['@id']}>
          <h3>{item.title ? item.title : item['@id']}</h3>
        </SiteLink>
      </div>
    </div>
  );
};

EventItem.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(EventItem);
