import React from 'react';

const SiteLink = props => {
  const { to, children } = props;
  let url = to.replace(process.env.REACT_APP_API_URL, '');

  url = process.env.REACT_APP_SITE_URL + url;
  return (
    <a href={url} {...props} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};
export default SiteLink;
