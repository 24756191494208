import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import {
  createInstance,
  MatomoProvider,
  useMatomo
} from '@datapunt/matomo-tracker-react';

const getMatomoInstance = () => {
  const urlBase = process.env.REACT_APP_MATOMO_URL_BASE;
  const siteId = process.env.REACT_APP_MATOMO_SITE_ID;
  const enabled = process.env.REACT_APP_MATOMO_ENABLED;

  if (urlBase && siteId) {
    const config = {
      urlBase,
      siteId,
      disabled: !enabled, // optional, false by default. Makes all tracking calls no-ops if set to true.
      linkTracking: true, // optional, default value: true
      configurations: {
        // optional, default value: {}
        // any valid matomo configuration, all below are optional
        disableCookies: true,
        setSecureCookie: true,
        setRequestMethod: 'POST'
      }
    };
    return createInstance(config);
  }
  return null;
};

const MatomoWrapper = withRouter(({ location, children }) => {
  let matomoInstance = getMatomoInstance();
  const { trackPageView } = useMatomo();
  const urlBase = process.env.MATOMO_URL_BASE;
  const siteId = process.env.MATOMO_SITE_ID;
  const enabled = process.env.MATOMO_ENABLED;

  useEffect(() => {
    if (matomoInstance) {
      matomoInstance.trackPageView({ href: location.pathname });
    }
  }, [location.pathname]);

  return matomoInstance ? (
    <MatomoProvider value={matomoInstance}>{children}</MatomoProvider>
  ) : (
    <>{children}</>
  );
});

export default MatomoWrapper;
