import React from 'react';
import { Header, Image } from 'semantic-ui-react';
import { object } from 'prop-types';

import './index.scss';

const BaseView = ({ item }) => (
  <div id="content" className="base-view">
    <Header as="h2">{item.title}</Header>
    {item.description && item.description.data && (
      <span className="description">
        <div dangerouslySetInnerHTML={{ __html: item.description.data }} />
      </span>
    )}
    <div className="image-and-text">
      {item.image && (
        <figure>
          <Image src={item.image.scales.large.download} />
        </figure>
      )}
      {item.text && item.text.data && (
        <div className="item-text">
          <div dangerouslySetInnerHTML={{ __html: item.text.data }} />
        </div>
      )}
    </div>
  </div>
);

BaseView.propTypes = {
  item: object
};

export default BaseView;
