// const paths = {
//   partecipa: '/it/news',
//   participate: '/en/news'
// };
import moment from 'moment';

const today = moment()
  .startOf('day')
  .format('YYYY-MM-DD HH:mm');
const tomorrow = moment()
  .add(1, 'days')
  .endOf('day')
  .format('YYYY-MM-DD HH:mm'); //domani sera (dopodomani alle 00:000)

// const event_metadata = [
//   'start',
//   'end',
//   'whole_day',
//   'open_end',
//   'thematic_area',
//   'image'
// ];

const baseQuery = {
  partecipa_guided_tours: {
    sort_on: 'start',
    fullobjects: true,
    b_size: 3,
    portal_type: 'Event',
    'Subject.query': ['APPVisiteGuidateIT'],
    'Subject.operator': 'and',
    start: today,
    end: tomorrow,
    app_search: true
    // metadata_fields: event_metadata
  },
  participate_guided_tours: {
    sort_on: 'start',
    b_size: 3,
    fullobjects: true,
    portal_type: 'Event',
    'Subject.query': ['APPVisiteGuidateEN'],
    'Subject.operator': 'and',
    start: today,
    end: tomorrow,
    app_search: true
    // metadata_fields: event_metadata
  },
  partecipa_next_events: {
    sort_on: 'start',
    b_size: 3,
    fullobjects: true,
    portal_type: 'Event',
    Subject: ['APPPartecipaIT'],
    app_search: true,
    start: today,
    end: tomorrow
    // metadata_fields: event_metadata
  },
  participate_next_events: {
    sort_on: 'start',
    b_size: 3,
    fullobjects: true,
    portal_type: 'Event',
    Subject: ['APPPartecipaEN'],
    app_search: true,
    start: today,
    end: tomorrow
    //metadata_fields: event_metadata
  },
  scopri: {
    sort_on: 'modified',
    sort_order: 'reverse',
    fullobjects: true,
    Subject: 'APPScopriIT'
  },
  discover: {
    sort_on: 'modified',
    sort_order: 'reverse',
    fullobjects: true,
    Subject: 'APPScopriEN'
  }
};

export const getBaseQuery = ({ language, type, id }) => {
  let query = {
    ...baseQuery[type],
    // path: paths[type] ? paths[type] : '',
    Language: language
  };
  if (id) {
    query.id = id;
  }
  return query;
};
