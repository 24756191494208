export const searchData = query => {
  const queryString = query
    ? Object.keys(query)
        .map(k => {
          if (typeof query[k] != 'object') {
            return `${encodeURIComponent(k)}=${encodeURIComponent(query[k])}`;
          } else {
            let array_params = [];
            query[k].forEach(v => {
              array_params.push(
                `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
              );
            });
            return array_params.join('&');
          }
        })
        .join('&')
    : null;

  const url = `${process.env.REACT_APP_API_URL}/@search?${queryString}`;
  return fetch(url, {
    headers: {
      Accept: 'application/json'
    }
  }).then(res => res.json());
};
