import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import Homepage from './components/Homepage';
import ParticipateListing from './components/listing/ParticipateListing';
import DiscoverListing from './components/listing/DiscoverListing';
import BookListing from './components/listing/BookListing';
import DetailView from './components/views/DetailView';
import AppHeader from './components/AppHeader';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import { IntlProvider, addLocaleData } from 'react-intl';

import MatomoWrapper from './MatomoWrapper';

import it from './i18n/it.json';
import en from './i18n/en.json';
import locale_en from 'react-intl/locale-data/en';
import locale_it from 'react-intl/locale-data/it';

import 'semantic-ui-css/components/item.min.css';
import 'semantic-ui-css/components/breadcrumb.min.css';
import 'semantic-ui-css/components/label.min.css';
import 'semantic-ui-css/components/menu.min.css';
import 'semantic-ui-css/components/transition.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/container.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/dropdown.min.css';
import 'semantic-ui-css/components/image.min.css';
import 'semantic-ui-css/components/table.min.css';
import 'semantic-ui-css/components/grid.min.css';
import 'semantic-ui-css/components/message.min.css';

export const AppContext = React.createContext({
  language: 'it',
  setLanguage: () => {}
});

addLocaleData([...locale_it, ...locale_en]);
const messages = {
  it,
  en
};

class App extends Component {
  constructor(props) {
    super(props);

    this.setLanguage = (lang) => {
      return () => {
        this.setState({
          language: lang
        });
      };
    };

    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      language: 'it',
      setLanguage: this.setLanguage
    };
  }

  render() {
    const { language } = this.state;

    const participate = (props) => (
      <Container>
        <ParticipateListing {...props} />
      </Container>
    );
    const Visit = (props) => (
      <Container>
        <DiscoverListing {...props} />
      </Container>
    );
    const Book = (props) => (
      <Container>
        <BookListing {...props} />
      </Container>
    );
    const Detail = (props) => (
      <Container>
        <DetailView {...props} />
      </Container>
    );

    return (
      <IntlProvider locale={language} messages={messages[language]}>
        <Router>
          <AppContext.Provider value={this.state}>
            <MatomoWrapper>
              <AppHeader />
              <Route path="/" exact component={Homepage} />
              <Route path="/participate" exact component={participate} />
              <Route path="/partecipa" exact component={participate} />
              <Route path="/participate/:id" exact component={Detail} />
              <Route path="/partecipa/:id" exact component={Detail} />
              <Route path="/visit" exact component={Visit} />
              <Route path="/visita" exact component={Visit} />
              <Route path="/visit/:id" exact component={Detail} />
              <Route path="/visita/:id" exact component={Detail} />
              <Route path="/book" exact component={Book} />
              <Route path="/prenota" exact component={Book} />
              <Route path="/book/:id" exact component={Detail} />
              <Route path="/prenota/:id" exact component={Detail} />
              <Footer />
              <ScrollToTop />
            </MatomoWrapper>
          </AppContext.Provider>
        </Router>
      </IntlProvider>
    );
  }
}

export default App;
