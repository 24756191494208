import React, { Component } from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { searchData } from '../../../helpers/api';
import BaseView from '../BaseView';
import EntiServiziStruttureView from '../EntiServiziStruttureView';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import { getBaseQuery } from '../../../helpers/queries';
import Loading from '../../Loading';
import Error from '../../Error';

import './index.scss';

class DetailView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      item: null,
      loading: true,
      error: null
    };
  }

  getSectionName = () => {
    const { match } = this.props;
    return match.path.replace(':id', '').replace(/\//g, '');
  };

  componentDidMount() {
    const { match, intl } = this.props;
    const id = match.params.id;
    const type = this.getSectionName();
    const query = {
      ...getBaseQuery({ type, language: intl.locale, id }),
      fullobjects: 1
    };
    searchData(query)
      .then(data => {
        const item = data.items[0];
        this.setState({
          ...this.state,
          item,
          loading: false
        });
      })
      .catch(error => {
        console.error('Error: ', error);
        this.setState({
          item: null,
          loading: false,
          error: error
        });
      });
  }

  getItemView = item => {
    switch (item['@type']) {
      case 'EntiServiziStrutture':
        return <EntiServiziStruttureView item={item} />;
      default:
        return <BaseView item={item} />;
    }
  };

  render() {
    const { item, loading, error } = this.state;
    const { intl, match } = this.props;
    const id = match.params.id;
    const sectionName = this.getSectionName();
    const renderer = error ? (
      <Error data={error} />
    ) : loading ? (
      <Loading />
    ) : (
      this.getItemView(item)
    );

    let breadcrumbsDetails = '';
    if (id) {
      breadcrumbsDetails = (
        <React.Fragment>
          <Breadcrumb.Section>
            <Link
              to={intl.formatMessage({
                id: `${sectionName}Link`,
                defaultMessage: '/participate'
              })}
            >
              <FormattedMessage id={sectionName} defaultMessage="Participate" />
            </Link>
          </Breadcrumb.Section>
        </React.Fragment>
      );
    } else {
      breadcrumbsDetails = (
        <Breadcrumb.Section>
          <FormattedMessage id={sectionName} defaultMessage="" />
        </Breadcrumb.Section>
      );
    }
    return (
      <div className="item-view">
        <Breadcrumb>
          <Breadcrumb.Section>
            <Link to="/">Home</Link>
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          {breadcrumbsDetails}
        </Breadcrumb>
        {renderer}
      </div>
    );
  }
}

DetailView.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(DetailView);
