// @flow
import * as React from 'react';
import { Modal, Image, Header } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import logo from './logo512.png';
import appleShare from './AppleShare.png';
import useIsIOS from './useisIOS';
import './modal.css';
import './index.css';

const InstallPWA = ({ intl }: any) => {
  const { prompt, isSafari } = useIsIOS();

  const [promptOpen, setPromptOpen] = React.useState(true);

  const title = intl.formatMessage({
    id: 'installpwa.title',
    defaultMessage: 'Install VisitModena NOW App'
  });

  const hidePrompt = () => setPromptOpen(false);

  return prompt && isSafari ? (
    <Modal
      open={promptOpen}
      onClose={hidePrompt}
      className="install-pwa-modal"
      closeIcon
    >
      <Modal.Content image>
        <Image size="medium" src={logo} wrapped />
        <Modal.Description>
          <Header>{title}</Header>
          <p>
            {intl.formatMessage({
              id: 'installpwa.content',
              defaultMessage:
                'Install this application on your homescreen for a better experience'
            })}
          </p>
          <div className="install-pwa-hint">
            <p>
              {intl.formatMessage({
                id: 'installpwa.hint.part1',
                defaultMessage: 'Tap'
              })}
            </p>
            <img src={appleShare} alt="Share" width="20" height="20" />
            <p>
              {intl.formatMessage({
                id: 'installpwa.hint.part2',
                defaultMessage: 'then "Add to Home Screen"'
              })}
            </p>
          </div>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  ) : null;
};

export default injectIntl(InstallPWA);
