import React from 'react';
import { object } from 'prop-types';
import SiteLink from '../../../../helpers/SiteLink';
import TagsView from '../TagsView';
import DefaultImageSVG from '../default-image.svg';

const DefaultItem = ({ location, item, rootLink }) => {
  const image = item.image ? item.image.scales.large.download : DefaultImageSVG;

  return (
    <div className="item default-item">
      <div className="item-title">
        <SiteLink to={item['@id']}>
          <h3>{item.title ? item.title : item.id}</h3>
        </SiteLink>
      </div>
      {/* <div className="item-border" /> */}
      <div className="item-content">
        <SiteLink to={item['@id']}>
          {item.thematic_area && (
            <TagsView subjects={[item.thematic_area.title]} />
          )}
          <figure>
            <img src={image} alt={item.title} loading="lazy" />
          </figure>
        </SiteLink>
      </div>
    </div>
  );
};

DefaultItem.propTypes = {
  item: object
};

export default DefaultItem;
