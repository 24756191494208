import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ListingContainer from '../ListingContainer';

import { AppContext } from '../../../App';
import { injectIntl, intlShape } from 'react-intl';
import { getBaseQuery } from '../../../helpers/queries';

const ParticipateListing = ({ intl, location }) => {
  const type_next_events = {
    it: 'partecipa_next_events',
    en: 'participate_next_events'
  };
  return (
    <AppContext.Consumer>
      {({ language }) => {
        const query_next_events = getBaseQuery({
          language,
          type: type_next_events[language]
        });
        return (
          <div className="category-view">
            <Breadcrumb>
              <Breadcrumb.Section>
                <Link to="/">Home</Link>
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right angle" />
              <Breadcrumb.Section active>
                {intl.formatMessage({
                  id: 'participate',
                  defaultMessage: 'participate'
                })}
              </Breadcrumb.Section>
            </Breadcrumb>

            <div id="content">
              <FormattedMessage
                id="participateDescription"
                defaultMessage="Experience an event"
              >
                {text => <h1>{text}</h1>}
              </FormattedMessage>

              <ListingContainer
                query={query_next_events}
                location={location}
                title={intl.formatMessage({
                  id: 'today and tomorrow events',
                  defaultMessage: 'Today and tomorrow events'
                })}
                linkMore={intl.formatMessage({
                  id: 'events_linkmore',
                  defaultMessage: '/en/events'
                })}
                linkMoreTitle={intl.formatMessage({
                  id: 'events_linkmore_title',
                  defaultMessage: 'Discover events'
                })}
              />
            </div>
          </div>
        );
      }}
    </AppContext.Consumer>
  );
};

ParticipateListing.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(ParticipateListing);
