import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment/min/moment-with-locales';
import momentTZ from 'moment-timezone';
import cx from 'classnames';
import { defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  eventFrom: {
    id: 'eventFrom',
    defaultMessage: 'Dal'
  },
  eventTo: {
    id: 'eventTo',
    defaultMessage: 'al'
  },
  eventFromTime: {
    id: 'eventFromTime',
    defaultMessage: 'dalle'
  },
  eventToTime: {
    id: 'eventToTime',
    defaultMessage: 'alle'
  }
});

const datesForDisplay = (start, end, locale) => {
  const timezone = 'UTC';
  momentTZ.defineLocale(locale, moment.localeData(locale)._config);

  const mStart = momentTZ.tz(start, timezone);

  const mEnd = momentTZ.tz(end, timezone);
  if (!mStart.isValid() || !mEnd.isValid()) {
    return null;
  }
  const sameYear = mStart.isSame(mEnd, 'year');
  const sameMonth = mStart.isSame(mEnd, 'month');
  const sameDay = mStart.isSame(mEnd, 'day');
  const sameTime =
    mStart.hour() === mEnd.hour() && mStart.minute() === mEnd.minute();
  const noStartTime = mStart.hour() === 0 && mStart.minute() === 0;
  const noEndTime = mEnd.hour() === 0 && mEnd.minute() === 0;
  const wholeDay = noStartTime && noEndTime && sameTime;

  return {
    sameYear,
    sameMonth,
    sameDay,
    sameTime,
    wholeDay,
    mStart,
    mEnd
  };
};

const When = ({
  start,
  end,
  show_times = true,
  _whole_day,
  open_end,
  intl
}) => {
  moment.locale(intl.locale);

  const datesInfo = datesForDisplay(start, end, intl.locale);
  if (!datesInfo) {
    console.warn('EventWhen: Received invalid start or end date.');
    return;
  }
  const {
    sameYear,
    sameMonth,
    sameDay,
    sameTime,
    wholeDay,
    mStart,
    mEnd
  } = datesInfo;

  const whole_day = _whole_day || wholeDay;

  return (
    <p
      className={cx('event-when', {
        'same-year': sameYear,
        'same-month': sameMonth,
        'same-day': sameDay,
        'same-time': sameTime,
        'whole-day': whole_day,
        'open-end': open_end
      })}
    >
      {!sameDay ? (
        <>
          {intl.formatMessage(messages.eventFrom)}
          &nbsp;
          <span className="start">
            <span className="start-date">
              {mStart.format(sameMonth ? 'D' : 'LL')}
            </span>

            {!whole_day &&
              !sameMonth &&
              show_times && (
                <>
                  {/* Plone has an optional word based on locale here */}
                  <span> </span>
                  <span className="start-time">{mStart.format('LT')}</span>
                </>
              )}
          </span>
          {!open_end && (
            <>
              &nbsp;
              {intl.formatMessage(messages.eventTo)}
              &nbsp;
              <span className="end">
                <span className="end-date">{mEnd.format('LL')}</span>
                {!whole_day &&
                  show_times && (
                    <>
                      {/* Plone has an optional word based on locale here */}
                      <span> </span>
                      <span className="end-time">{mEnd.format('LT')}</span>
                    </>
                  )}
              </span>
            </>
          )}
        </>
      ) : (
        <>
          {whole_day && (
            <span className="start-date">{mStart.format('LL')}</span>
          )}
          {open_end &&
            !whole_day && (
              <>
                <span className="start-date">{mStart.format('LL')}</span>

                {show_times && (
                  <>
                    &nbsp;
                    {intl.formatMessage(messages.eventFromTime)}
                    &nbsp;
                    <span className="start-time">{mStart.format('LT')}</span>
                  </>
                )}
              </>
            )}
          {!(whole_day || open_end) && (
            <>
              <span className="start-date">{mStart.format('LL')}</span>

              {show_times && (
                <>
                  &nbsp;
                  {intl.formatMessage(messages.eventFromTime)}
                  &nbsp;
                  <span className="start-time">{mStart.format('LT')}</span>
                  &nbsp;
                  {intl.formatMessage(messages.eventToTime)}
                  &nbsp;
                  <span className="end-time">{mEnd.format('LT')}</span>
                </>
              )}
            </>
          )}
        </>
      )}
    </p>
  );
};

When.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string,
  whole_day: PropTypes.bool,
  open_end: PropTypes.bool,
  show_times: PropTypes.bool
};

const EventWhen = injectIntl(When);

export default EventWhen;
