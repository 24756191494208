import React, { Component } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';

import SiteLink from '../../helpers/SiteLink';

import './index.scss';

class OffcanvasMenu extends Component {
  state = {
    isOpen: false
  };

  closeMenu = () => {
    this.setState({
      isOpen: false
    });
  };

  isMenuOpen = state => {
    this.setState({
      isOpen: state.isOpen
    });
  };

  render() {
    const { intl } = this.props;

    return (
      <div className="collapse-menu">
        <Menu
          isOpen={this.state.isOpen}
          onStateChange={this.isMenuOpen}
          outerContainerId="header"
          customBurgerIcon={<Icon name="bars" />}
          customCrossIcon={<Icon name="close" />}
        >
          <Link onClick={this.closeMenu} to="/">
            <span>Home</span>
          </Link>
          <Link
            onClick={this.closeMenu}
            to={intl.formatMessage({
              id: 'visitLink',
              defaultMessage: '/visit'
            })}
          >
            <FormattedMessage id="visit" defaultMessage="Visit" />
          </Link>
          <Link
            onClick={this.closeMenu}
            to={intl.formatMessage({
              id: 'participateLink',
              defaultMessage: '/participate'
            })}
          >
            <FormattedMessage id="participate" defaultMessage="participate" />
          </Link>
          <Link
            onClick={this.closeMenu}
            to={intl.formatMessage({
              id: 'bookLink',
              defaultMessage: '/book'
            })}
          >
            <FormattedMessage id="book" defaultMessage="Book" />
          </Link>
          <SiteLink
            to={intl.formatMessage({
              id: 'aboutUsLink',
              defaultMessage: '/it/chi-siamo'
            })}
            onClick={this.closeMenu}
          >
            <FormattedMessage id="aboutUs" defaultMessage="About us" />
          </SiteLink>
          <SiteLink
            to={intl.formatMessage({
              id: 'privacyLink',
              defaultMessage: '/it/privacy'
            })}
            onClick={this.closeMenu}
          >
            <FormattedMessage id="privacy" defaultMessage="Privacy" />
          </SiteLink>
        </Menu>
      </div>
    );
  }
}

OffcanvasMenu.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(OffcanvasMenu);
