import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import ListingContainer from '../ListingContainer';
import { AppContext } from '../../../App';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { getBaseQuery } from '../../../helpers/queries';

import './index.scss';

const BookListing = ({ intl, location }) => {
  const type_guided_tours = {
    it: 'partecipa_guided_tours',
    en: 'participate_guided_tours'
  };

  return (
    <AppContext.Consumer>
      {({ language }) => {
        const query_guided_tours = getBaseQuery({
          language,
          type: type_guided_tours[language]
        });
        return (
          <div className="category-view">
            <Breadcrumb>
              <Breadcrumb.Section>
                <Link to="/">Home</Link>
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right angle" />
              <Breadcrumb.Section active>
                {intl.formatMessage({
                  id: 'book',
                  defaultMessage: 'Book'
                })}
              </Breadcrumb.Section>
            </Breadcrumb>

            <div id="content" className="book">
              <FormattedMessage
                id="bookDescription"
                defaultMessage="Choose your experience"
              >
                {text => <h1>{text}</h1>}
              </FormattedMessage>

              <ListingContainer
                query={query_guided_tours}
                location={location}
                linkMore={intl.formatMessage({
                  id: 'guided_tours_linkmore',
                  defaultMessage: '/en/experiences'
                })}
                linkMoreTitle={intl.formatMessage({
                  id: 'guided_tours_linkmore_title',
                  defaultMessage: 'Discover all experiences'
                })}
              />
            </div>
          </div>
        );
      }}
    </AppContext.Consumer>
  );
};

BookListing.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(BookListing);
