import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'semantic-ui-react';

import { AppContext } from '../../../App';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { getBaseQuery } from '../../../helpers/queries';
import ListingContainer from '../ListingContainer';
import './index.scss';

const DiscoverListing = ({ intl, location }) => {
  const type_discover = {
    it: 'scopri',
    en: 'discover'
  };

  return (
    <AppContext.Consumer>
      {({ language }) => {
        const query_discover = getBaseQuery({
          language,
          type: type_discover[language]
        });
        return (
          <div className="category-view">
            <Breadcrumb>
              <Breadcrumb.Section>
                <Link to="/">Home</Link>
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right angle" />
              <Breadcrumb.Section active>
                {intl.formatMessage({
                  id: 'visit',
                  defaultMessage: 'Visit'
                })}
              </Breadcrumb.Section>
            </Breadcrumb>

            <div id="content">
              <FormattedMessage
                id="visitDescription"
                defaultMessage="Start your trip"
              >
                {text => <h1>{text}</h1>}
              </FormattedMessage>

              <ListingContainer query={query_discover} location={location} />
            </div>
          </div>
        );
      }}
    </AppContext.Consumer>
  );
};

DiscoverListing.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(DiscoverListing);
