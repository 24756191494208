import React from 'react';
import { Pagination, Icon, Grid } from 'semantic-ui-react';
import EventItem from '../ListingItems/EventItem';
import DefaultItem from '../ListingItems/DefaultItem';
import Loading from '../../Loading';
import { FormattedMessage } from 'react-intl';
import './index.scss';

const ListingResultsContainer = ({
  items,
  loading,
  error,
  items_total,
  batching,
  activePage,
  b_size,
  handlePaginationChange,
  location,
  align
}) => {
  if (loading) {
    return <Loading />;
  }
  return (
    <React.Fragment>
      {/* <Header as="h2">
        <FormattedMessage
          id="search.numResults"
          defaultMessage={`{items_total, plural,one {Found} other {Found}} {items_total, number} {items_total, plural,one {result}other {results}}`}
          values={{ items_total }}
        />
      </Header> */}

      {items.length === 0 && (
        <div className="no-results">
          <FormattedMessage id="no results" defaultMessage="No results found" />
        </div>
      )}

      {items.length > 0 && (
        <Grid
          stackable
          columns={3}
          verticalAlign={align || 'top'}
          className="listing"
        >
          {items.map(item => {
            const params = { item, location };
            let renderedComponent = null;
            switch (item['@type']) {
              case 'Event':
                renderedComponent = (
                  <Grid.Column key={item['@id']}>
                    <EventItem {...params} />
                  </Grid.Column>
                );
                break;
              default:
                renderedComponent = (
                  <Grid.Column key={item['@id']}>
                    <DefaultItem {...params} />
                  </Grid.Column>
                );
                break;
            }
            return renderedComponent;
          })}
        </Grid>
      )}
      {batching ? (
        <div className="pagination-container">
          <Pagination
            activePage={activePage}
            firstItem={{
              content: <Icon name="angle double left" />,
              icon: true
            }}
            lastItem={{
              content: <Icon name="angle double right" />,
              icon: true
            }}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
            onPageChange={handlePaginationChange}
            totalPages={Math.ceil(items_total / b_size)}
          />
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default ListingResultsContainer;
