import 'react-app-polyfill/ie9';
import 'core-js/es6/array';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
import * as serviceWorker from './serviceWorker';

const bowser = require('bowser');

if (window) {
  const browser = bowser.getParser(window.navigator.userAgent);
  const isValidBrowser = browser.satisfies({
    firefox: '>64',
    chrome: '>22',
    opera: '>12',
    'opera mini': '>0',
    'android browser': '>4.1',
    'opera mobile': '>12',
    'uc browser': '>=11.8',
    'samsung internet': '>=4',
    vivaldi: '>0'
  });

  const isSafariOriOS =
    browser.getBrowser().name === 'Safari' || browser.getOS().name === 'iOS';

  if (isValidBrowser && !isSafariOriOS) {
    document.querySelector('body').classList.add('webp');
  } else {
    document.querySelector('body').classList.add('no-webp');
  }
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
