import React from 'react';
import { Container, List, Segment, Grid, Image, Icon } from 'semantic-ui-react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import LogoModena from './LogoComuneModena.png';
//import LoghettiModena from './LoghettiModena.png';
//import LogoEmiliaromagnaturismo from './LogoEmiliaromagnaturismo.svg';
import SiteLink from '../../helpers/SiteLink';

import './index.scss';

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright'
  },
  comune_modena: {
    id: 'Comune di Modena',
    defaultMessage: 'Comune di Modena'
  },
  comune_unesco: {
    id: 'Unesco - Patrimonio Mondiale',
    defaultMessage: 'Unesco - Patrimonio Mondiale'
  },
  emiliaromagnaturismo: {
    id: 'Emilia Romagna turismo',
    defaultMessage: 'Emilia Romagna turismo'
  },
  subscribe_newsletter_label: {
    id: 'Subscribe Newsletter Label',
    defaultMessage: 'Iscriviti alla nostra newsletter'
  },
  subscribe_newsletter_submit: {
    id: 'Subscribe Newsletter Submit',
    defaultMessage: 'Invia'
  },
  download_app: {
    id: 'Download App',
    defaultMessage: 'Scarica la nostra app mobile!'
  },
  organize_your_event: {
    id: 'organize your event',
    defaultMessage: 'Organizza il tuo evento'
  },
  chi_siamo: {
    id: 'Chi siamo',
    defaultMessage: 'Chi siamo'
  },
  contattaci: {
    id: 'Contattaci',
    defaultMessage: 'Contattaci'
  },
  regolamento: {
    id: 'Regolamento',
    defaultMessage: 'Regolamento'
  },
  sitemap: {
    id: 'Mappa del sito',
    defaultMessage: 'Mappa del sito'
  },
  dichiarazione_accessibilita: {
    id: 'Dichiarazione di accessibilità',
    defaultMessage: 'Accessibilità'
  },
  dove_siamo: {
    id: 'Dove siamo',
    defaultMessage: 'Dove siamo'
  }
});

const Footer = ({ intl }) => (
  <>
    <Segment
      vertical
      padded
      inverted
      color="grey"
      textAlign="center"
      className="footer"
    >
      <Grid container stackable celled="internally" className="loghi">
        <Grid.Row verticalAlign="middle" className="fixed-height">
          <Grid.Column>
            <Image
              src={LogoModena}
              alt={intl.formatMessage(messages.comune_modena)}
              title={intl.formatMessage(messages.comune_modena)}
              loading="lazy"
            />
          </Grid.Column>
        </Grid.Row>
        {/* <Grid.Row verticalAlign="middle">
          <Grid.Column>
            <Image
              src={LoghettiModena}
              alt={intl.formatMessage(messages.comune_unesco)}
              title={intl.formatMessage(messages.comune_unesco)}
              loading="lazy"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row verticalAlign="middle">
          <Grid.Column>
            <a href="https://www.emiliaromagnaturismo.it/">
              <Image
                src={LogoEmiliaromagnaturismo}
                alt={intl.formatMessage(messages.emiliaromagnaturismo)}
                title={intl.formatMessage(messages.emiliaromagnaturismo)}
                loading="lazy"
              />
            </a>
          </Grid.Column>
        </Grid.Row> */}
        <Grid.Row verticalAlign="middle">
          <Grid.Column textAlign="center" className="iat">
            <h3>
              <FormattedMessage
                id="IAT - UFFICIO INFORMAZIONE E ACCOGLIENZA TURISTICA"
                defaultMessage="IAT - UFFICIO INFORMAZIONE E ACCOGLIENZA TURISTICA"
              />
            </h3>
            <p>Piazza Grande 14, Modena</p>
            <p>
              <FormattedMessage id="Telephone" defaultMessage="Telefono" />: +39
              059/2032660 - e-mail: info@visitmodena.it
            </p>
            <p>
              <div className="bold">
                <FormattedMessage
                  id="Orario di apertura"
                  defaultMessage="Orario di apertura"
                />
              </div>
              <FormattedMessage
                id="Dal Lunedì al Sabato"
                defaultMessage="Dal Lunedì al Sabato"
              />{' '}
              9.00-18.00
              <br />
              <FormattedMessage
                id="Domenica e festivi"
                defaultMessage="Domenica e festivi"
              />{' '}
              9.30-18.00
              <br />
              <FormattedMessage id="Chiuso" defaultMessage="Chiuso" /> 25
              dicembre
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    <Segment
      role="contentinfo"
      vertical
      padded
      secondary
      textAlign="center"
      className="footer"
    >
      <Container>
        <Grid columns={2} stackable>
          <Grid.Row>
            <Grid.Column textAlign="left" mobile={16} tablet={16} computer={16}>
              <div className="brand">
                <div className="bold">VISIT MODENA</div>
                <div className="infos">
                  <FormattedMessage
                    id="SitePayoff"
                    defaultMessage="Official tourist information site of the municipality of Modena"
                  />
                </div>
              </div>
            </Grid.Column>

            <Grid.Column
              verticalAlign="middle"
              mobile={16}
              tablet={16}
              computer={16}
            >
              <Grid columns="2" stackable>
                <Grid.Row>
                  <Grid.Column
                    className="social-links"
                    textAlign="left"
                    mobile={16}
                    tablet={16}
                    computer={16}
                  >
                    <List horizontal className="socials">
                      <List.Item
                        as="a"
                        href="https://www.facebook.com/visitmodena"
                        target="_blank"
                        rel="noopener noreferer"
                        title="Facebook"
                      >
                        <Icon name="facebook f" />
                      </List.Item>
                      <List.Item
                        as="a"
                        href="https://twitter.com/visitmodena"
                        target="_blank"
                        rel="noopener noreferer"
                        title="Twitter"
                      >
                        <Icon name="twitter" />
                      </List.Item>
                      <List.Item
                        as="a"
                        href="https://instagram.com/visitmodena/"
                        target="_blank"
                        rel="noopener noreferer"
                        title="Instagram"
                      >
                        <Icon name="instagram" />
                      </List.Item>
                      <List.Item
                        as="a"
                        href="https://www.youtube.com/channel/UCq9l1Dxip6SJ5sDt_z1VliA"
                        target="_blank"
                        rel="noopener noreferer"
                        title="YouTube"
                      >
                        <Icon name="youtube" />
                      </List.Item>
                      <List.Item
                        as="a"
                        href="https://www.pinterest.com/visitmodena"
                        target="_blank"
                        rel="noopener noreferer"
                        title="Pinterest"
                      >
                        <Icon name="pinterest" />
                      </List.Item>
                    </List>
                  </Grid.Column>
                  <Grid.Column
                    className="footer-links"
                    textAlign="center"
                    mobile={16}
                    tablet={16}
                    computer={16}
                  >
                    <SiteLink
                      to={intl.formatMessage({
                        id: 'aboutUsLink',
                        defaultMessage: '/it/chi-siamo'
                      })}
                    >
                      <FormattedMessage
                        id="aboutUs"
                        defaultMessage="About us"
                      />
                    </SiteLink>
                    <SiteLink
                      to={intl.formatMessage({
                        id: 'privacyLink',
                        defaultMessage: '/it/privacy'
                      })}
                    >
                      <FormattedMessage id="privacy" defaultMessage="Privacy" />
                    </SiteLink>
                  </Grid.Column>
                  <Grid.Column
                    className="language"
                    textAlign="right"
                    mobile={16}
                    tablet={16}
                    computer={16}
                  >
                    <FormattedMessage id="Language" defaultMessage="Lingua" />{' '}
                    {intl.locale}
                    <Image
                      src={require(`./Languages/${intl.locale}.svg`)}
                      alt={intl.locale}
                      title={intl.locale}
                      loading="lazy"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </>
);

export default injectIntl(Footer);
